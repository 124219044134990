import "core-js/modules/es6.regexp.split";
import "core-js/modules/es6.regexp.replace";
import _createForOfIteratorHelper from "/var/jenkins_home/workspace/zippo-mdm-vue-prd/node_modules/@babel/runtime-corejs2/helpers/esm/createForOfIteratorHelper.js";
import "core-js/modules/es6.function.name";
import "core-js/modules/es6.array.find";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import waves from '@/directive/waves'; // waves directive
import GeneralEditForm from '@/components/GeneralEditForm';
import GeneralTable from '@/components/GeneralTable';
import { getLocalTimestamp } from '@/utils';
import Sticky from '@/components/Sticky';
import { generalQuery, generalDetail, generalValueLabelList } from '@/api/general';
import { auditPass, auditReject, auditSteps, auditCancel } from '@/api/shopaudit';
export default {
  name: 'Index',
  components: {
    GeneralEditForm: GeneralEditForm,
    GeneralTable: GeneralTable,
    Sticky: Sticky
  },
  directives: {
    waves: waves
  },
  data: function data() {
    return {
      id: this.$route.params.id,
      isOnline: this.$route.query.bShopChannel != null && parseInt(this.$route.query.bShopChannel) == 1 ? true : false,
      viewType: parseInt(this.$route.query.viewType != null ? this.$route.query.viewType : 1),
      updatePkId: this.$route.query.updatePkId,
      fromPage: this.$route.query.fromPage,
      url: {},
      tableName: "v_client_audit_process_instance_zippo",
      //tableColumns: [],
      editColumns: [],
      editForm: {},
      rules: {},
      loading: false,
      showTable: false,
      comment: '',
      auditStepsData: [],
      activeStep: 0,
      auditFinished: false,
      imgBaseUrl: process.env.VUE_APP_BASE_API + 'image/'
    };
  },
  computed: {
    codes: function codes() {
      return this.$store.getters.codeDataDictionary.codes;
    }
  },
  created: function created() {
    this.setTableColumns();
    //console.log(this.updatePkId)
    this.getDetailData();
    this.getAuditStepsData();
  },
  watch: {
    $route: function $route(to, from) {
      //console.log(to)
      this.id = this.$route.params.id;
      this.setTableColumns();
      this.getDetailData();
      this.getAuditStepsData();
    }
  },
  methods: {
    setTableColumns: function setTableColumns() {
      var _this = this;
      var tdds = this.$store.getters.tableDataDictionary.tables;
      var tableColumns = JSON.parse(JSON.stringify(tdds.find(function (value) {
        return value.name === _this.tableName;
      }).tableColumns));
      // this.editColumns = tableColumns.filter(n => (!n.virtual || n.virtual == 0 || n.virtual == 2)&& (n.visible || n.editable))
      // this.editColumns = this.editColumns.filter(n=>n.code!='c_rec_revisor'&&n.code!='c_rec_revise_time')
      this.editColumns = [];
      var onlineCodes = ["b_shop_channel", "b_authorize_index", "b_shop_old_code", "b_distributor_code", "b_distributor_name", "b_shop_distributor_code", "b_shop_distributor_name", "b_shop_name", "b_operation_mode", "b_business_type", "b_shop_contact", "b_shop_tel", "b_shop_email", "b_sales_user", "b_e_platform", "b_shop_address", "c_rec_creator", "c_rec_create_time"];
      //"b_serial","b_paper_no","b_owner_name","b_owner_idcard",
      //"b_owner_contact","b_authorize_start_date","b_authorize_end_date","b_has_print","b_first_print_date",
      //"c_cancel_operator","c_cancel_time","b_shop_comment",
      //"c_rec_creator","c_rec_create_time","c_rec_revisor","c_rec_revise_time"]
      //console.log(this.$refs.GeneralTable.listQuery.param)
      var offlineCodes = ["b_shop_channel", "b_authorize_index", "b_old_shop_code", "b_distributor_code", "b_distributor_id", "b_shop_distributor_code", "b_shop_distributor_name", "b_shop_name", "b_operation_mode", "b_shop_type", "b_business_type", "b_shop_contact", "b_shop_tel", "b_shop_email", "b_sales_user", "b_mall_name", "b_mall_floor", "b_mall_room_number", "b_shop_address", "c_rec_creator", "c_rec_create_time"];
      //"b_serial","b_paper_no","b_owner_name","b_owner_idcard",
      //"b_owner_contact","b_authorize_start_date","b_authorize_end_date","b_has_print","b_first_print_date",
      //"c_cancel_operator","c_cancel_time","b_shop_comment",
      //"c_rec_creator","c_rec_create_time","c_rec_revisor","c_rec_revise_time"]
      var currentCodes = this.isOnline ? onlineCodes : offlineCodes;
      var _iterator = _createForOfIteratorHelper(currentCodes),
        _step;
      try {
        var _loop = function _loop() {
          var code = _step.value;
          // console.log(code)
          var tempColumn = tableColumns.filter(function (t) {
            return code == t.code;
          });
          // console.log(tempColumn)
          if (tempColumn != null && tempColumn.length > 0) {
            _this.editColumns.push(tempColumn[0]);
          }
        };
        for (_iterator.s(); !(_step = _iterator.n()).done;) {
          _loop();
        }
      } catch (err) {
        _iterator.e(err);
      } finally {
        _iterator.f();
      }
    },
    setSelectOptions: function setSelectOptions() {
      var _this2 = this;
      var _iterator2 = _createForOfIteratorHelper(this.tableColumns.filter(function (c) {
          return c.isOption !== null && c.editable !== null && c.isOption === true && c.editable === true;
        })),
        _step2;
      try {
        var _loop2 = function _loop2() {
          var item = _step2.value;
          //  console.log(item.code)
          var codeDD = _this2.codes.filter(function (n) {
            return n.propId === item.propId;
          });
          if (codeDD && codeDD[0] && codeDD[0].data.length > 0) {
            item.options = codeDD[0].data;
            // console.log(item.options)
          }
          // console.log(item.optionsUrl)
          if (item.optionsUrl && item.optionsUrl.indexOf('/') >= 0) {
            _this2.getOptionItemSelectOptions(item);
          }
        };
        for (_iterator2.s(); !(_step2 = _iterator2.n()).done;) {
          _loop2();
        }
      } catch (err) {
        _iterator2.e(err);
      } finally {
        _iterator2.f();
      }
    },
    getDetailData: function getDetailData() {
      var _this3 = this;
      // console.log('getDetailData')
      // console.log(this.url.query)
      if (this.id && this.id !== '') {
        // this.id = this.url.params.id
        this.loading = true;
        return new Promise(function (resolve, reject) {
          generalDetail('/shop-audit/audit/shop', _this3.id).then(function (response) {
            _this3.editForm = response.data.data;
            if (_this3.updatePkId == null && _this3.editForm.c_id != null) {
              _this3.updatePkId = _this3.editForm.c_id;
            } else if (_this3.updatePkId == null) {
              _this3.updatePkId = 0;
            }
            if (response.data.code !== '0') {
              _this3.$notify({
                title: _this3.$t('notification.error.title'),
                message: response.data.message,
                type: 'error',
                duration: 5000
              });
            }
            _this3.loading = false;
            // console.log(response.data)
            resolve(response);
          }).catch(function (error) {
            console.log(error);
            _this3.loading = false;
            reject(error);
          });
        });
      }
    },
    getOptionItemSelectOptions: function getOptionItemSelectOptions(item) {
      var _this4 = this;
      // console.log('getOptionItemSelectOptions')
      if (item.optionsUrl && item.optionsUrl.length > 0) {
        this.loading = true;
        return new Promise(function (resolve, reject) {
          generalValueLabelList(item.optionsUrl).then(function (response) {
            item.options = response.data.data;
            // console.log(item.options)
            if (response.data.code !== '0') {
              _this4.$notify({
                title: _this4.$t('notification.error.title'),
                message: response.data.message,
                type: 'error',
                duration: 5000
              });
            }
            _this4.loading = false;
            // console.log(response.data)
            resolve(response);
          }).catch(function (error) {
            console.log(error);
            _this4.loading = false;
            reject(error);
          });
        });
      }
    },
    pass: function pass() {
      var _this5 = this;
      this.$confirm(this.$t('messageBox.confirm.body'), this.$t('messageBox.confirm.title'), {
        confirmButtonText: this.$t('button.confirm'),
        cancelButtonText: this.$t('button.cancel'),
        type: 'warning'
      }).then(function () {
        _this5.loading = true;
        // console.log(this.editForm)
        var updateData = {};
        Object.assign(updateData, _this5.editForm);
        // console.log(updateData)
        return new Promise(function (resolve, reject) {
          auditPass(_this5.id, _this5.comment, updateData).then(function (response) {
            // console.log(response)
            if (response.data.code === '0') {
              _this5.$notify({
                title: _this5.$t('notification.success.title'),
                message: _this5.$t('notification.success.body'),
                type: 'success',
                duration: 5000
              });
              var nextAudit = response.data.data;
              if (nextAudit != null && nextAudit.next_audit_id != null && nextAudit.next_pk_id != null) {
                var randomNum = Math.round(Math.random() * 10000);
                var path = '/master/shop/audit/' + nextAudit.next_audit_id + '?viewType=' + _this5.viewType + '&updatePkId=' + nextAudit.next_pk_id + '&t=' + randomNum;
                _this5.$router.push({
                  path: path
                });
              } else {
                _this5.close();
              }
            } else {
              _this5.$notify({
                title: _this5.$t('notification.error.title'),
                message: response.data.message,
                type: 'error',
                duration: 5000
              });
            }
            _this5.loading = false;
            resolve(response);
          }).catch(function (error) {
            _this5.loading = false;
            reject(error);
          });
        });
        // this.getDetailData()
      }).catch(function (error) {
        console.error(error);
        _this5.loading = false;
      });
      this.loading = false;
    },
    reject: function reject() {
      var _this6 = this;
      if (this.comment == null || this.comment == '') {
        this.$notify({
          title: this.$t('notification.title'),
          message: this.$t('label.auditCommentNotEmpty'),
          type: 'warning',
          duration: 5000
        });
        return;
      }
      this.$confirm(this.$t('messageBox.confirm.body'), this.$t('messageBox.confirm.title'), {
        confirmButtonText: this.$t('button.confirm'),
        cancelButtonText: this.$t('button.cancel'),
        type: 'warning'
      }).then(function () {
        _this6.loading = true;
        // console.log(this.editForm)
        var updateData = {};
        Object.assign(updateData, _this6.editForm);
        // console.log(updateData)
        return new Promise(function (resolve, reject) {
          auditReject(_this6.id, _this6.comment, updateData).then(function (response) {
            // console.log(response)
            if (response.data.code === '0') {
              _this6.$notify({
                title: _this6.$t('notification.success.title'),
                message: _this6.$t('notification.success.body'),
                type: 'success',
                duration: 5000
              });
              _this6.close();
            } else {
              _this6.$notify({
                title: _this6.$t('notification.error.title'),
                message: response.data.message,
                type: 'error',
                duration: 5000
              });
            }
            _this6.loading = false;
            resolve(response);
          }).catch(function (error) {
            _this6.loading = false;
            reject(error);
          });
        });
        // this.getDetailData()
      }).catch(function (error) {
        console.error(error);
        _this6.loading = false;
      });
      this.loading = false;
    },
    cancel: function cancel() {
      var _this7 = this;
      this.$confirm(this.$t('messageBox.confirm.body'), this.$t('messageBox.confirm.title'), {
        confirmButtonText: this.$t('button.confirm'),
        cancelButtonText: this.$t('button.cancel'),
        type: 'warning'
      }).then(function () {
        _this7.loading = true;
        return new Promise(function (resolve, reject) {
          auditCancel(_this7.id).then(function (response) {
            // console.log(response)
            if (response.data.code === '0') {
              _this7.$notify({
                title: _this7.$t('notification.success.title'),
                message: _this7.$t('notification.success.body'),
                type: 'success',
                duration: 5000
              });
              _this7.close();
            } else {
              _this7.$notify({
                title: _this7.$t('notification.error.title'),
                message: response.data.message,
                type: 'error',
                duration: 5000
              });
            }
            _this7.loading = false;
            resolve(response);
          }).catch(function (error) {
            _this7.loading = false;
            reject(error);
          });
        });
        // this.getDetailData()
      }).catch(function (error) {
        console.error(error);
        _this7.loading = false;
      });
      this.loading = false;
    },
    getAuditStepsData: function getAuditStepsData() {
      var _this8 = this;
      if (this.id && this.id !== '') {
        // this.id = this.url.params.id
        this.loading = true;
        return new Promise(function (resolve, reject) {
          auditSteps(_this8.id).then(function (response) {
            if (response.data.code !== '0') {
              _this8.$notify({
                title: _this8.$t('notification.error.title'),
                message: response.data.message,
                type: 'error',
                duration: 5000
              });
            }
            _this8.auditStepsData = response.data.data;
            var totalPassNum = 0;
            for (var i = 0; i < _this8.auditStepsData.length; i++) {
              var s = _this8.auditStepsData[i];
              if (s.b_audit_result != null && s.b_audit_result == 0) {
                _this8.activeStep = i;
              } else if (s.b_audit_result == 1) {
                totalPassNum++;
              } else if (s.b_audit_result == 2 || s.b_audit_result == -1) {
                _this8.auditFinished = true;
              }
              if (totalPassNum == _this8.auditStepsData.length) {
                _this8.auditFinished = true;
              }
            }
            _this8.loading = false;
            // console.log(response.data)
            resolve(response);
          }).catch(function (error) {
            console.log(error);
            _this8.loading = false;
            reject(error);
          });
        });
      }
    },
    getAuditStepStatus: function getAuditStepStatus(value) {
      if (value == null) {
        return 'wait';
      } else if (value == 0) {
        return 'process';
      } else if (value == 1) {
        return 'success';
      } else if (value == 2 || value == -1) {
        return 'error';
      }
    },
    tableRowClassName: function tableRowClassName(_ref) {
      var row = _ref.row,
        rowIndex = _ref.rowIndex;
      if (rowIndex === 0) {
        return 'warning-row';
      }
      return '';
    },
    getLocalTime: function getLocalTime(timestamp) {
      return getLocalTimestamp(timestamp);
    },
    getTitle: function getTitle() {
      return this.$t("label.auditAuthorize");
    },
    close: function close() {
      if (this.fromPage) {
        if (this.viewType == 1) {
          var randomNum = Math.round(Math.random() * 10000);
          this.$router.push('/?viewType=' + this.viewType + '&' + randomNum);
        } else {
          this.$router.push('/?viewType=' + this.viewType);
        }
      } else {
        this.$router.push('/master/shop/audit/list?viewType=' + this.viewType);
      }
    },
    getImageArr: function getImageArr(arr) {
      var s = arr.replace('[', '').replace(']', '');
      return s.split(/,|\n/).filter(function (n) {
        return n != null && n.length > 0;
      });
    },
    getImageUrl: function getImageUrl(img) {
      //console.log(img)
      if (img.indexOf('http') == 0) {
        return img;
      }
      return this.imgBaseUrl + img;
    },
    getImagePreviewSrcList: function getImagePreviewSrcList(img) {
      if (img.indexOf('http') == 0) {
        return [img];
      }
      var arr = [];
      arr.push(this.imgBaseUrl + img);
      return arr;
    }
  }
};